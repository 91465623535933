import React from 'react'
import { getStoreSecurityDeviceExceptionsById } from 'mti-jsclient-shared'
import { alertTypes } from './options'
import AlertContainer from './index'

const getAllAlerts = (store, session) => {
  let alerts = getStoreAlerts(store) || []
  let securityDevices = getStoreSecurityDeviceExceptionsById(session, store.id)
  securityDevices.forEach((device) => {
    alerts = alerts.concat(getSecurityDeviceAlerts(device))
  })

  return alerts.map((alert, i) => ({ ...alert, index: i })) //index is used to calculate alert position when there are multiple alerts
}

const getSecurityDeviceAlerts = (securityDevice) => {
  let alerts = []

  if (securityDevice.alarming) {
    alerts.push({
      alertType: alertTypes.ALARMING,
      resourceId: securityDevice.id,
      resourceName: `resourceTypes.${securityDevice.deviceType}`,
      messagePath: 'alerts.securityDeviceAlarming', //path in translation file
      action: securityDevice.status.action,
    })
  }

  return alerts
}

const getStoreAlerts = (store) => {
  let alerts = []

  if (store.state === 'offline') {
    alerts.push({
      alertType: alertTypes.OFFLINE,
      resourceId: store.id,
      resourceName: store.name,
      messagePath: 'alerts.storeOffline', //path in translation file
      action: null,
      index: 0, //used to calculate alert position when there are multiple alerts
    })
  }

  return alerts
}

const displayAlerts = (alerts, style) => {
  const content = alerts.map((alert) => {
    const { component } = alert.alertType
    let AlertComponent = component
    return <AlertComponent key={alert.index} {...alert} />
  })
  return <AlertContainer style={style} alerts={content} />
}

export { getAllAlerts, displayAlerts }
